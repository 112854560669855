import React from 'react';
import { useState } from 'react';
import { Pagination } from 'react-bootstrap';
import AmigurumiCard from './ProductItem';

const AmigurumiList = ({ data }) => {

    const [currentPage, setCurrentPage] = useState(1);
    const amigurumiPerPage = 3

    const totalAmigurumis = data

    const indexOfLastAmigurumi = currentPage * amigurumiPerPage
    const indexOfFirstAmigurumi = indexOfLastAmigurumi - amigurumiPerPage

    const currentsAmigurumi = totalAmigurumis.slice(
        indexOfFirstAmigurumi,
        indexOfLastAmigurumi
    )

    const paginate = (pageNumber) => setCurrentPage(pageNumber);






    return (
        <div className='amigurumi'>
            <div className='amigurumi__card'>
                {currentsAmigurumi.map((item, index) =>(
                    <AmigurumiCard
                    key={index}
                    nom={item.nom}
                    image={item.image}
                    description={item.description}
                    lien={item.lien}
                    />
                ))}
            </div>
            <div>
            <Pagination>
          {totalAmigurumis.length > amigurumiPerPage &&
            Array.from({
              length: Math.ceil(
                totalAmigurumis.length / amigurumiPerPage
              ),
            }).map((item, index) => (
              <Pagination.Item
                key={index}
                active={index + 1 === currentPage}
                onClick={() => paginate(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
        </Pagination>
            </div>
        </div>

    )
}

export default AmigurumiList;