import React from 'react'
import phone from '../Media/phone.png'

const Social = () => {

    return (
        <section className="ma-section">
          <div className="contenu">
              
              <div className="texte">
                  <h2>Retrouvez toutes mes créations sur Instagram</h2>
                  <p>Et si l'une de mes créations vous intéresse et ne figure pas sur ma boutique, envoyez-moi un e-mail et je vous la réaliserai sur mesure !</p>
                  <a href="https://www.instagram.com/zacktails/" className="landing-button">Mon Instagram</a>
                  <a href="mailto:zacktails35@gmail.com" className="landing-button">Mon mail</a>

              </div>
              <img src={phone} alt="Mon Image" className="image" />
          </div>
      </section>
    )
}

export default Social