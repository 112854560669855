import React from 'react';
import logo from '../Media/logo.png'


const Home = () => {


    return(
        
        <header className='header'>
            <div className='header__logo'>

                <img src={logo} alt='Poulpy, le logo de ZackTails' />

            </div>
            <div className='header__content'>
                <h1 className='header__title'>
                    Zack Tails, Passion Amigurumi
                </h1>
                <p class="header__quote">
                    Peluches amusantes et mignonnes pour petits et grands!
                </p>
                <a href="https://www.etsy.com/shop/zacktails35/?etsrc=sdt" className='header__button'>
                    Ma Boutique Etsy
                </a>
            </div>
        </header>
    )
}

export default Home