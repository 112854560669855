import logo from './logo.svg';
import './App.css';

import ProductGallery from './Section/ProductSection';
import Gallery from './Section/EventTool/EventList';
import Event from './Section/Event';
import Home from './Section/Header';
import Social from './Section/SocialMedia';
import FooterPage from './Section/Footer';

function App() {
  return (
    <div className="App">
      <Home />
      <Event />
      <ProductGallery />
      <Social />
      <FooterPage />
    </div>
  );
}

export default App;
