import React, { useState, useEffect } from 'react';
import AmigurumiList from './ProductTool/ProductList';

const ProductGallery = () => {
    const [amigurumiData, setAmigurumiData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('http://backend.zacktails.fr/api/amigurumi');
                const data = await response.json();
                setAmigurumiData(data);
            } catch (error) {
                console.error('Oups. Pas de peluches ici', error);
            }
            };

            fetchData();
        }, []);

        return (
            <section id="amigurumi">
                <h2 className='amigurumi__bigtitle'>Mes créations</h2>
                <AmigurumiList data={amigurumiData} />
            </section>
        );
    
};

export default ProductGallery