import React, { useState, useEffect } from 'react';

const Event = () => {
    const [nom, setNom] = useState('');
    const [date, setDate] = useState('');
    const [description, setDescription] = useState('');
    const [image, setImage] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('http://backend.zacktails.fr/api/evenement');
                const data = await response.json();
                console.log(data); // Vérifie si les données sont correctement récupérées
                const lastEntry = data[data.length - 1];

                setNom(lastEntry.nom);
                setDate(lastEntry.date);
                setDescription(lastEntry.description);
                setImage(lastEntry.image);
                
            } catch (error) {
                console.error('Erreur:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <section className='event'>
            <h1 className='event__title'>{nom}</h1>
            <div className='event__description' dangerouslySetInnerHTML={{ __html: description}}></div>
            <p className='event__date'>{date}</p>
            <img src={image} alt={nom} className='event__image'/>
        </section>
    );
};

export default Event;
