import React from 'react';
import { Card as BootsrtapCard} from 'react-bootstrap';

const AmigurumiCard = ({ nom, image, description, lien}) => {
    return (
        <BootsrtapCard>
            <BootsrtapCard.Body>
                <BootsrtapCard.Title>
                    {nom}
                </BootsrtapCard.Title>
                <img src={image} className='amigurumi__image'/>
                <div className='event__description' dangerouslySetInnerHTML={{ __html: description}}></div>
                <a href={lien} className='header__button'>Acheter</a>
            </BootsrtapCard.Body>
        </BootsrtapCard>
    )
}

export default AmigurumiCard;