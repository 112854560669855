import React from 'react'
import poulpy from '../Media/poulpy.png'

const FooterPage = () => {

    return (
    <footer>
    <div className='footer__logo'>
        <img src={poulpy} alt="logo de Poulpy" />
    </div>
    <div className='footer__content'>
        <h3 className='footer__title'>
            Zack Tails
        </h3>
    </div>
    <div className='footer__copyright'>
        <p>© 2023 Zacktails. Tous droits réservés.

</p>
<p>Site réalisé par <span><a href="https://kilianduclos.com">Kilian Duclos</a></span></p>
    </div>
    </footer>
    )
}

export default FooterPage